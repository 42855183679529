<template>
  <div>
    <el-form>
      <el-row :gutter="20">
        <el-col :span="5">
          <el-form-item label="请输入学生姓名">
            <el-input v-model="search.name" placeholder="请输入学生姓名">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请输入身份证号">
            <el-input v-model="search.id_card" placeholder="请输入身份证号">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-button type="primary" @click="onPaymentList"> 搜索 </el-button>
        </el-col>
      </el-row>
      <!-- <el-row :gutter="10">
        <el-col :span="6">

          <el-button
            v-permission="['financeAddFees']"
            type="primary"
            @click="onChangeTheTime"
          >
            添加
          </el-button>

          <el-button type="primary" @click="templateDownload">
            模板下载
          </el-button>

          <el-button type="primary" @click="excelImport">导入</el-button>
          <el-button
            :loading="loading"
            type="success"
            @click="onPayTheFeesexport"
          >
            导出
          </el-button>
        </el-col>
      </el-row> -->
    </el-form>

    <!-- 列表 -->
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      :header-cell-style="{ background: '#f2f2f2' }"
      ><el-table-column label="学生资料">
        <el-table-column fixed prop="name" label="学生姓名" />
        <el-table-column fixed prop="id_card" label="身份证号" />
        <el-table-column prop="grade" label="年级" />
        <el-table-column fixed prop="college_name" label="系部" />
        <el-table-column prop="domain_name" label="专业" />
        <el-table-column prop="level_name" label="层次" />
      </el-table-column>
    </el-table>
    <el-table
      :data="settingData"
      border
      style="width: 100%; margin-top: 50px"
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <el-table-column label="收费项目">
        <el-table-column prop="name" label="缴费项目名称" />
        <el-table-column prop="total_amount" label="缴费金额" />
        <el-table-column prop="years" label="学年度" />
        <el-table-column label="缴费渠道">
          <template v-slot="{ row }">
            {{ row.pay_type ? '微信支付' : '光大银行' }}
          </template>
        </el-table-column>
        <el-table-column prop="start_time" label="开始时间" />
        <el-table-column prop="end_time" label="	结束时间" />
      </el-table-column>
    </el-table>
    <el-table
      :data="paylistData"
      border
      style="width: 100%; margin-top: 50px"
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <el-table-column label="已收费记录">
        <el-table-column fixed prop="orderid" label="订单号" />
        <el-table-column fixed prop="payername" label="姓名" />
        <el-table-column prop="payercardno" label="	身份证号" />
        <el-table-column fixed prop="paySettingsName" label="缴费项目" />
        <el-table-column prop="amt" label="缴费金额" />
        <el-table-column label="支付状态">
          <template v-slot="{ row }">
            {{ row.payState ? '已支付' : '未支付' }}
          </template>
        </el-table-column>
        <el-table-column label="缴费渠道">
          <template v-slot="{ row }">
            {{ row.pay_type ? '微信支付' : '光大银行' }}
          </template>
        </el-table-column>
        <el-table-column prop="payTime" label="支付时间" />
        <el-table-column label="退费状态">
          <template v-slot="{ row }">
            {{ row.refundState ? '已退费' : '未退费' }}
          </template>
        </el-table-column>
        <el-table-column prop="upTime" label="退费时间" />
      </el-table-column>
    </el-table>

    <!-- 分页 -->

    <!-- <el-row style="margin-top: 20px">
      <el-col>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="search.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="search.total"
        >
        </el-pagination>
      </el-col>
    </el-row> -->

    <!-- 修改时间 -->
    <change-the-time
      v-model="timeVisible"
      :item="item"
      :onPaymentList="onPaymentList"
    ></change-the-time>

    <!-- 详情 -->
    <pay-details
      v-model="detailVisible"
      :item="item"
      :onPaymentList="onPaymentList"
    ></pay-details>
    <graduate-edit
      v-model="oldStudentShow"
      :studentID="studentID"
      :onPaymentList="onPaymentList"
    ></graduate-edit>
  </div>
</template>

<script setup>
// import { useRouter } from 'vue-router'
import { listOfHierarchicalListingAPI } from '@/api/specialized'
import { ref } from 'vue'
import { orderAllAPI } from '@/api/finance'
// import { useRouter } from 'vue-router'
// import { ElMessage } from 'element-plus'
import changeTheTime from './components/changeTheTime.vue'
import payDetails from './components/payDetails.vue'
import graduateEdit from './components/graduateEdit.vue'
// const router = useRouter()
/** 搜索 S */
const search = ref({
  page: 1, // 当前页
  limit: 10, // 每页显示条数
  total: 0,
  name: '', // 缴费名称
  id_card: '' // 身份证号
})

// 层次下拉
const hierarchicalData = ref([])
const onLayerPullDown = async () => {
  const data = await listOfHierarchicalListingAPI()
  hierarchicalData.value = data
}
onLayerPullDown()
/** 搜索 E */

/** 表格 S */
// 列表数据
const tableData = ref([])
const settingData = ref([])
const paylistData = ref([])
const onPaymentList = async () => {
  const { stuinfo, setting, paylist } = await orderAllAPI(search.value)
  tableData.value.splice(0, tableData.value.length)
  // search.value.total = total
  tableData.value.push(stuinfo)
  settingData.value = setting
  paylistData.value = paylist
  console.log('stuinfo', stuinfo)
}
// onPaymentList()

// 修改
const studentID = ref({})
const oldStudentShow = ref(false)
/** 表格 E */

/**
 *  分页 S
 */
// 每页获取多少数据
// const handleSizeChange = (val) => {
//   search.value.limit = val
//   onPaymentList()
// }

// 页码值
// const handleCurrentChange = (val) => {
//   search.value.page = val
//   onPaymentList()
// }
/**
 *  分页 E
 */

/** 修改时间 S */
const item = ref({})
/** 修改时间 E */
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
}
</style>
